<template>
  <div class="card mt-3">
    <div class="surface-card p-4 mt-4 shadow-2 border-round">
      <div class="p-fluid grid mb-5">
        <div class="col-12 justify-content-center align-items-center flex">
          <Avatar v-if="clinical.urlLogo" :image="clinical.urlLogo" class="mr-2" size="large" style="height: 10rem; width: 10rem;background-color:#2196F3; object-fit: cover;color: #ffffff" shape="circle"/><br>
          <Avatar icon="pi pi-user" v-if="!clinical.urlLogo" class="mr-2" size="large" style="height: 10rem; width: 10rem;background-color:#2196F3; color: #ffffff" shape="circle"/><br>
        </div>
        <div class="col-12 justify-content-center align-items-center flex">
          <FileUpload id="avatar2" auto mode="basic" name="avatar" :custom-upload="true" @select="onEnviar($event)" @uploader="onSendFirebase" accept="image/*" :maxFileSize="5000000" class="p-button-outlined p-button-plain" chooseLabel="Enviar Imagem"></FileUpload>
        </div>
      </div>
      <div class="grid formgrid p-fluid">
        <div class="field-radiobutton col-2 md:col-2">
          <RadioButton disabled="true" inputId="PJ" name="tipoClinica" value="PJ" v-model="clinical.clinicalType" />
          <label for="city2">Pessoa Jurídica</label>
        </div>
        <div class="field-radiobutton col-2 md:col-2">
          <RadioButton disabled="true" inputId="PF" name="tipoClinica" value="PF" v-model="clinical.clinicalType" />
          <label for="city2">Pessoa Física</label>
        </div>
        <div class="field-radiobutton col-8 md:col-8"></div>
        <div class="field mb-4 col-12 md:col-3" v-if="clinical.clinicalType === 'PJ'">
          <label for="cnpj" :class="{'p-error': v$.clinical.cnpj.$error}">CNPJ*</label>
          <div class="p-inputgroup">
            <InputMask mask="99.999.999/9999-99"
                       id="cnpj"
                       disabled="true"
                       @blur="v$.clinical.cnpj.$touch"
                       :class="{'p-invalid': v$.clinical.cnpj.$error}"
                       v-model="clinical.cnpj"/>
          </div>
          <small class="p-error" v-for="error of v$.clinical.cnpj.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field mb-4 col-12 md:col-3" v-if="clinical.clinicalType === 'PF'">
          <label for="cpf" :class="{'p-error': v$.clinical.cpf.$error}">CPF*</label>
          <div class="p-inputgroup">
            <InputMask mask="999.999.999-99"
                       id="cpf"
                       disabled="true"
                       @blur="v$.clinical.cpf.$touch"
                       autofocus
                       :class="{'p-invalid': v$.clinical.cpf.$error}"
                       v-model="clinical.cpf"/>
          </div>
          <small class="p-error" v-for="error of v$.clinical.cpf.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field mb-4 col-12">
          <label for="clinica" :class="{'p-error': v$.clinical.name.$error}">Nome da Clínica*</label>
          <div class="p-inputgroup">
            <InputText id="clinica" type="text"
                       required="true"
                       maxlength="50"
                       @blur="v$.clinical.name.$touch"
                       autofocus
                       v-model="clinical.name"
                       :class="{'p-invalid': v$.clinical.name.$error}"
            />
          </div>
          <small class="p-error" v-for="error of v$.clinical.name.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field mb-4 col-12 md:col-3">
          <label for="celular" :class="{'p-error': v$.clinical.celular.$error}">Celular*</label>
          <div class="p-inputgroup">
            <InputMask
                mask="(99) 99999-9999"
                id="celular"
                @blur="v$.clinical.celular.$touch"
                autofocus
                :class="{'p-invalid': v$.clinical.celular.$error}"
                v-model="clinical.celular"/>
          </div>
          <small class="p-error" v-for="error of v$.clinical.celular.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="col-12 md:col-9"></div>
        <div class="field mb-4 col-9 md:col-3">
          <label for="cep" class="font-medium text-900">CEP</label>
          <InputMask mask="99999-999" id="cep" v-model="clinical.cep"/>
        </div>
        <div class="col-12 md:col-2 align-items-center flex">
          <Button icon="pi pi-search" label="Buscar" class="p-button" @click.prevent="findByCep"/>
        </div>
        <div class="field mb-4 md:col-7"></div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="email2" class="font-medium text-900">Estado</label>
          <Dropdown v-model="uf" :options="ufs" optionLabel="sigla" placeholder="Selecione um Estado" />
        </div>

        <div class="field mb-4 col-12 md:col-6">
          <label for="email2" class="font-medium text-900">Cidade</label>
          <InputText id="email2" type="text" v-model="clinical.cidade"/>
        </div>
        <div class="field mb-4 col-12 md:col-5">
          <label for="email2" class="font-medium text-900">Bairro</label>
          <InputText id="email2" type="text" v-model="clinical.bairro"/>
        </div>
        <div class="field mb-4 col-12 md:col-5">
          <label for="email2" class="font-medium text-900">Logradouro</label>
          <InputText id="email2" v-model="clinical.logradouro"/>
        </div>
        <div class="field mb-4 col-12 md:col-2">
          <label for="email2" class="font-medium text-900">Número</label>
          <InputText id="email2" v-model="clinical.numero"/>
        </div>
        <div class="field mb-4 col-12 md:col-8">
          <label for="complemento" class="font-medium text-900">Complemento</label>
          <InputText id="complemento" v-model="clinical.complemento"/>
        </div>

        <div class="field mb-4 col-12">
          <label for="privacy2" class="font-medium text-900">Você esta de acordo com nossos termos e privacidade?</label>
          <div class="flex align-items-center">
            <InputSwitch id="privacy2"
                         v-model="clinical.acceptTerms"
            ></InputSwitch>
          </div>
        </div>
      </div>
    </div>
    <Toolbar class="mb-2 mt-3">
      <template v-slot:end>
        <div class="my-2">
          <Button label="Salvar" class="p-button-success" @click.prevent="save"></Button>
        </div>
      </template>
    </Toolbar>
  </div>
</template>

<script>
import BrasilService from "@/service/BrasilService";
import _ from "lodash";
import CepService from "@/service/CepService";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required, requiredIf} from "@vuelidate/validators";
import { cpf, cnpj } from 'cpf-cnpj-validator';
import ClinicalService from "@/service/ClinicalService";
import firebase from "firebase/compat";

const validarCnpj = (value) => !helpers.req(value) || cnpj.isValid(value);
const validarCpf = (value) => !helpers.req(value) || cpf.isValid(value);

export default {
  name: "admin-profile",
  setup: () => ({v$: useVuelidate()}),
  created() {
    this.brasilService = new BrasilService();
    this.cepService = new CepService();
    this.clinicalService = new ClinicalService();
  },

  data() {
    return {
      brasilService: null,
      cepService: null,
      clinicalService: null,
      clinical: {
        uf: null,
        cidade: null,
        acceptTerms: false,
        clinicalType: 'PJ'
      },
      ufs: [],
      uf: null,
      local: null,
      tipoClinica: 'pj'
    }
  },
  validations() {
    return {
      clinical: {
        name: {
          required: helpers.withMessage('Campo Nome é obrigatório.', required)
        },
        cnpj: {
          requiredIf: helpers.withMessage('Campo CNPJ é obrigatório.', requiredIf(this.clinical.clinicalType === 'PJ')),
          validarCnpj: helpers.withMessage('CNPJ informado é inválido.', validarCnpj)
        },
        cpf: {
          requiredIf: helpers.withMessage('Campo CPF é obrigatório.', requiredIf(this.clinical.clinicalType === 'PF')),
          validarCpf: helpers.withMessage('CPF informado é inválido.', validarCpf)
        },
        celular: {
          required: helpers.withMessage('Campo Celular é obrigatório.', required)
        }
      }
    }
  },
  async mounted() {
    await this.findAllUf();
    await this.findByClinical();
  },
  methods: {

    async findByClinical() {
      const response = await this.clinicalService.findClinicalById();
      if(response.status === 200) {
        this.clinical = response.data;
        this.uf = _.find(this.ufs, ['sigla', this.clinical.uf]);
        localStorage.removeItem("clinical");
        localStorage.setItem("clinical", JSON.stringify(this.clinical));
      }
    },

    async save() {
      const isFormValid = await this.v$.$validate();
      if (isFormValid) {
        if(!this.clinical.acceptTerms) {
          this.$toast.add({severity:'warn', summary: 'Alerta', detail: 'Para continuar você precisa aceitar nossos Termos.', life: 3000});
          return;
        }
        this.loader = this.$loading.show();
        this.clinical.uf = this.uf.sigla;
        console.info(`CLINICA: ${JSON.stringify(this.clinical)}`);
        const response = await this.clinicalService.save(this.clinical);
        if(response.status === 200) {
          this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Clínica cadastrado com sucesso.', life: 3000});
        }
        this.loader.hide();
      }
      return;
    },

    async findAllUf() {
      const ufss = [];
      const response = await this.brasilService.findEstados();
      if (response.status === 200) {
        response.data.forEach((uf) => {
          ufss.push(uf);
        });
        this.ufs = _.orderBy(ufss, ['sigla'], ['asc'])
      }
    },

    async findByCep() {
      const loader = this.$loading.show();
      const response = await this.cepService.findCep(this.clinical.cep);
      if (response.status === 200) {
        this.clinical.uf = response.data.state;
        this.clinical.cidade = response.data.city;
        this.clinical.bairro = response.data.neighborhood;
        this.clinical.logradouro = response.data.street;

        const resp = await this.brasilService.findById(response.data.state);
        this.uf = resp.data;
      }
      loader.hide();
    },

    onEnviar(event){
      this.imageData = event.files[0];
    },

    onSendFirebase() {
      const loader = this.$loading.show();
      const fileName = this.clinical.id + '.jpg';
      const storageRef = firebase.storage().ref(`clinical/profile/admin/${this.clinical.id}/${fileName}`).put(this.imageData);
      storageRef.on(`state_changed`,snapshot=>{
            this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          }, error=>{
            console.log(error.message);
            loader.hide();
          },
          ()=>{this.uploadValue=100;
            storageRef.snapshot.ref.getDownloadURL().then((url)=>{
              this.clinical.urlLogo =url;
              console.info(`URL: ${this.clinical.urlLogo}`);
              loader.hide();
            });
          }
      );
    },
  }
}
</script>

<style scoped>

</style>
